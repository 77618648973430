import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import Bio from "../components/Bio";
import PostTags from "../components/PostTags";
import SocialLinks from "../components/SocialLinks";
import SEO from "../components/SEO";
import config from "../../data/SiteConfig";
import * as postStyles from "./mindmapppost.module.scss";

import "./prism-okaidia.css";

export default ({ data, pageContext }) => {
  const { slug, nexttitle, nextslug, prevtitle, prevslug } = pageContext;
  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;
  const date = postNode.fields.date;
  if (!post.id) {
    post.id = slug;
  }
  if (post.template == "twocol") {
    return (
      {/*<Layout>
        <main>
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          <div>
            <h1>{post.title} </h1>
            <p className={postStyles.postMeta}>
              {date} &mdash; {postNode.timeToRead} Min1 Read{" "}
            </p>
            <div className={postStyles.postMeta}>
              <PostTags tags={post.tags} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: postNode.html }} />

            <hr />
            <Bio config={post} />
            <div className={postStyles.postMeta}>
              <SocialLinks postPath={slug} postNode={postNode} />
            </div>
          </div>
          <nav>
            <ul className={postStyles.pagination}>
              <li>
                <Link to={prevslug} rel="prev">
                  ← {prevtitle}
                </Link>
              </li>
              <li>
                <Link to={nextslug} rel="next">
                  {nexttitle}→
                </Link>
              </li>
            </ul>
          </nav>
        </main>
      </Layout>*/}
    );
  } else {
    return (
      <Layout>
        <main>
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          <div>
            <h1>{post.title}</h1>
            <p className={postStyles.postMeta}>
              {date} &mdash; {postNode.timeToRead} Min Read{" "}
            </p>
            {/* <div className={postStyles.postMeta}>
              <PostTags tags={post.tags} />
            </div> */}
            <div className={postStyles.mapdiv} dangerouslySetInnerHTML={{ __html: postNode.html }} />

            <hr />
            <Bio config={post} />
            <div className={postStyles.postMeta}>
              <SocialLinks postPath={slug} postNode={postNode} />
            </div>
          </div>
          <nav>
            <ul className={postStyles.pagination}>
              <li>
                <Link to={prevslug} rel="prev">
                  ← {prevtitle}
                </Link>
              </li>
              <li>
                <Link to={nextslug} rel="next">
                  {nexttitle}→
                </Link>
              </li>
            </ul>
          </nav>
        </main>
      </Layout>
    );
  }
};

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query MindmapPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        author
        cover
        date
        categories
        tags
      }
      fields {
        slug
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
